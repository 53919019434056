
<template>
  云存储搭建中，wait for wait<br>
    
</template>
    <script >
    
    
    export default {
      name: 'MemoryUnit',

      data()
      {
        return {
          
        } 
      },
      methods:{
        
        
        
      },
      
      mounted(){
      },
      beforeUnmount(){
      },
      
      
      
    }
    
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    h3 {
      margin: 40px 0 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #42b983;
    }
    </style>
    
    
    